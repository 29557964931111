export const environment = {

  // production: false,
  // apiUrl: "https://api.prophitapp.com:83/api",
  // fileUploadUrl: "https://api.prophitapp.com:83/img",
  // // version: '0.0.2',
  // PAYPAL_SANDBOX_CLIENT_ID: 'ASJF2mSrQBKu4dXTSoVUwywuJyQl26pDF7d9z7AOFy1TZWdF7shy4YhJz0AtcQ9lpIRfyQRdaKwG7oMZ',
  // PAYPAL_SANDBOX_BASE_URL:'https://api-m.sandbox.paypal.com/v1',
  // PAYPAL_SANDBOX_SECRET_CODE:'EGjQdlASZXj_Sm5dbIpMR1uUbQp9EgNMAgdtVEJ0M-Bmwak3tr-e7ttoDUDDEi_fzcuXQySV1fhmqh9N'

  production: false,

  baseUrl: "https://prophit-app.azurewebsites.net",
  apiUrl: "https://prophit-api.azurewebsites.net/api",
  fileUploadUrl: "https://prophit-api.azurewebsites.net/img",
  // baseUrl: "http://localhost:4200",
  // apiUrl: "http://localhost:53320/api",
  // fileUploadUrl: "http://localhost:53320/img",
  stripeCustomerPortalTestUrl: "https://pay.prophitapp.com/p/login/test_eVa5ln3Pc245eqY6oo",

  // baseUrl: "https://prophit-app-staging.azurewebsites.net",
  // apiUrl: "https://prophit-api-staging.azurewebsites.net/api",
  // fileUploadUrl: "https://prophit-api-staging.azurewebsites.net/img",

  // apiUrl: "https://prophit-api.azurewebsites.net/api",
  // fileUploadUrl: "https://prophit-api.azurewebsites.net/img",

  // version: '0.0.2',

  PAYPAL_SANDBOX_CLIENT_ID: 'Ac7bBQV7ExhTqCYoA5HSNWaAIho7ZjaS2G3-mQeJWA8YIfFp6P_1QVZSn_6WOomse7HLdSse9sO3KtPU',
  PAYPAL_SANDBOX_BASE_URL: 'https://api-m.paypal.com/v1',
  PAYPAL_SANDBOX_SECRET_CODE: 'EN9GdDWEauR2IHzwpzzzxBZngSWYf0XehujI4BHWik55EyPz6a-t_SS_xiTK4-eL-lT3m1OkiwDK_ihL'

};

// export const environment = {
//   production: false,
//   apiUrl: "https://prophitapi.dashtechinc.com/api",
//   fileUploadUrl: "https://prophitapi.dashtechinc.com/img",
//   version: '0.0.0',
//   PAYPAL_SANDBOX_CLIENT_ID: 'Ac7bBQV7ExhTqCYoA5HSNWaAIho7ZjaS2G3-mQeJWA8YIfFp6P_1QVZSn_6WOomse7HLdSse9sO3KtPU',
//   PAYPAL_SANDBOX_BASE_URL:'https://api-test.paypal.com/v1',
//   PAYPAL_SANDBOX_SECRET_CODE:'EN9GdDWEauR2IHzwpzzzxBZngSWYf0XehujI4BHWik55EyPz6a-t_SS_xiTK4-eL-lT3m1OkiwDK_ihL'
// };
